.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
}
.inputsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inputRow {
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.inputDivider {
  width: 20px;
}

.contactDetailsRow {
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.termsRow > label {
  align-items: center;
}

@media (max-width: 1100px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .inputRow, .contactDetailsRow {
    flex-direction: column;
  }
}

.wrapper {
  display: flex;
  flex:1;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: var(--font-size-s);
  margin-top: 10px;
}

.inputs {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
}

.inputs > button {
  color: var(--sywt-blue-lighter);
  background: none;
  padding: 0 !important;
  margin: 0 10px;
  font-size: var(--font-size-m);
}

.inputs > button:hover {
  background: none;
}

.selected {
  color: var(--sywt-green) !important;
}


.stepsContainer {
  display:flex;
  margin-bottom:30px;
}

.steps {
  display:flex;
}

.stepNumber {
  padding-right: 40px;
  display:flex;
  color: var(--sywt-gold) !important;
}

.stepNumber > h2 {
  display:flex;
  font-size: var(--font-size-xl);
  font-weight:900;
}

.stepDescription {
  flex:9;
  display:flex;
  flex-direction: column;
}

.stepDescription > h2 {
  text-align: left;
  font-weight:700;
  font-size: var(--font-size-l);
  color: white;
}

.stepDescription > p {
  font-weight: 300;
  font-size: var(--font-size-m);
  padding: 0;
  margin: 0;
  margin-top: 10px;
  color: var(--sywt-silver);
}

.stepsContainer > a {
  font-weight: 300;
  font-size: var(--font-size-m);
}
.watchImage {
  position: fixed;
  left: -2px;
  top: -1px;
  bottom: 0;
  height: 100%;
  z-index: -1;
}

.container {
  position: fixed;
  top: var(--navbar-height);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  display: flex;
  margin: auto;
  justify-content: flex-end;
  z-index: -1;
  padding-bottom: 80px;
  padding-top: var(--top-padding);
}

.textBlock {
  margin: auto;
  margin-right: 0;
  text-align: center;
  width: 80%;
}

.textBlock > h1 {
  font-size: var(--font-size-xxl);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  margin: 0;
}

.silverText {
  font-size: var(--font-size-m);
  font-weight: 300;
  color: var(--sywt-silver);
  margin: 0;
}

.textBlock > span {
  padding-top: var(--top-padding);
}

.goldText {
  font-size: var(--font-size-l);
  color: var(--sywt-gold) !important;
  font-weight: 800 !important;
  margin: 0;
  margin-top: 10px;
}

.getOffersButton {
  margin-top: 3vh;
}

@media only screen and (max-width: 1300px) {
  .watchImage {
    display: none;
  }
  .textBlock {
    width: 90%;
    margin-right: auto;
  }
}

.imagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 4%;
  flex-wrap: wrap;
}

.twoImageGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
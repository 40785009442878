.watchImage {
  position: fixed;
  top: 0;
  left: -5px;
  height: 100%;
  bottom: 0;     
}

.container {
  padding-top: var(--top-padding);
  padding-bottom: 30px;
  position: fixed;
  top: var(--navbar-height);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  overflow-y: auto;
  z-index: -1;
}

.textBlock {
  padding-right: 5%;
  width: 65%;
  margin: auto;
  margin-right:0;
  display: flex;
  flex-direction: column;
}

.textBlock > h1 {
  font-size: var(--font-size-xxl);
  text-align: left;
  margin-bottom: 10px;
}

.textBlock > h2 {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: left;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: var(--font-size-l);
}

.textBlock > p {
  font-size: var(--font-size-m);
  font-weight: 300;
  color: var(--sywt-silver) !important;
  margin-top: 0;
}

@media (max-width: 1600px) {
  .watchImage {
    left: -50px;
  }
}

@media (max-width: 1450px) {
  .watchImage {
    display: none;
  }
  .textBlock {
    margin-right: auto;
    width: 90%;
    padding-right: 0;
  }
}
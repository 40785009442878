.container {
  display:flex;
  flex-direction:column;
  position: fixed;
  top: var(--navbar-height);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30px;
  padding-top: var(--top-padding);
  overflow-y: auto;
  color: var(--sywt-silver);
  z-index: -1;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.container::-webkit-scrollbar {
    display: none;
}

.container h1 {
  text-align: left;
  font-size: var(--font-size-xl);
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.container h2 {
  color: var(--sywt-gold) !important;
  text-align: left;
  font-size: var(--font-size-m);
  font-weight:600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.container > p {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: var(--font-size-s);
}

.container > ul {
  font-size: var(--font-size-s);
}

.container > ul > li {
  padding-bottom: 7px;
  padding-top: 7px;
  list-style-type: none;
}

.container > ul > b {
  color: var(--sywt-gold) !important;
}

.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  padding-top: var(--top-padding);
  padding-bottom: 30px;
  bottom: var(--footer-height);
  top: var(--navbar-height);
  left: 0;
  right: 0;
  padding-bottom: 30px;
  overflow-y: auto;
  color: var(--sywt-silver);
  z-index: -1;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.subContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
}

.container h1 {
  text-align:left;
  margin-bottom:20px;
  font-size: var(--font-size-xxl);
}

.stepsContainer {
  display:flex;
  flex-direction: column;
}

@media (max-width: 1100px) {
  .subContainer {
    width: 90%;
  }
}
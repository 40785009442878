.backIcon {
    position: fixed;
    top: 10px;
    left: 10px;
    font-size: 3rem;
    color: var(--sywt-silver);
    z-index: 1000;
}

.backIcon:hover {
    color: white;
    cursor: pointer;
}
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

:root {
  font-size: 14px;
  --sywt-blue: #3c4046;
  --sywt-blue-lighter: #595d62;
  --sywt-blue-dark: #222323;
  --sywt-silver: #dedede;
  --sywt-gold: #d3ba8d;
  --sywt-green: #8fd4b7;
  --sywt-green-dark: #7eb6a0;

  --font-size-xxl: 4.2rem;
  --font-size-xl: 3.5rem;
  --font-size-l: 2rem;
  --font-size-m: 1.5rem;
  --font-size-s: 1.1rem;
  --font-size-xs: 0.8rem;
  --input-width: max(15vw, 160px);
  --input-height: max(4vh, 30px);
  --navbar-height: 68px;
  --footer-height: 26px;
  --top-padding: 30px;
}

body {
  background-color: var(--sywt-blue);
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin: 0;
  color: white;
  -ms-overflow-style:none;
}

h1 {
  text-align: center;
  font-size: var(--font-size-xl);
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  margin: 0;
  color: white;
}

h2 {
  text-align: center;
  font-size: var(--font-size-l);
  font-weight: 300;
  margin: 0;
}

h3 {
  text-align: center;
  font-size: var(--font-size-m);
  font-weight: 300;
  margin: 0;
  color: var(--sywt-silver);
}

a {
  font-weight: 800;
  color: var(--sywt-green);
  text-decoration: none;
}

a:hover {
  color: var(--sywt-green-dark);
  cursor: pointer;
}

p {
  font-size: var(--font-size-s);
}

button {
  background-color: var(--sywt-green);
  border: none;
  border-radius: 5px;
  font-family: "Nunito Sans", sans-serif;
  padding-top: clamp(4px, 1vw, 6px) !important;
  padding-left: clamp(40px, 5vw, 80px) !important;
  padding-right: clamp(40px, 5vw, 80px) !important;
  padding-bottom: clamp(4px, 1vw, 6px) !important;
  font-weight: 800;
  font-size: max(1.3vw, 18px);
}

button:not(:disabled):hover {
  cursor: pointer;
  background-color: var(--sywt-green-dark);
}

button:disabled {
  background-color: var(--sywt-green-dark);
}

.centered {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1100px) {
  :root {
    font-size: 15px;
    --top-padding: 20px;
  }
}

@media (max-width: 800px) {
  :root {
    font-size: 13px;
    --top-padding: 10px;
  }
}

@media (max-width: 500px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  :root {
    font-size: 11px;
  }
}

@media (max-height: 600px) {
  :root {
    --footer-height: 5px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
}

.inputsContainer {
  display: flex;
  flex-direction: column;
}

.inputRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.inputDivider {
  width: 20px;
}

.inputRow > label {
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: var(--font-size-s);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
}

.textContainer > label {
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: var(--font-size-s);
}

.textContainer > label > textarea {
  background-color: var(--sywt-blue-lighter);
  border: 2px solid transparent !important;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  font-family: "Lato", sans-serif;
  color: white;
  font-size: var(--font-size-s);
  width:100%;
  height: 12vh;
}

.textContainer > label > textarea:focus {
  outline: none;
  border: 2px solid var(--sywt-green) !important;
}

@media (max-width: 1100px) {
  .container {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .inputRow {
    flex-direction: column;
  }
  .textContainer > label > textarea {
    height: 18vh;
  }
}
.bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: var(--footer-height);
  background-color: white;
  height: 5px;
  overflow: visible;
  z-index: 100;
}

.barGold {
  background-color: var(--sywt-gold);
  position: relative;
  left: 0;
  height: 100%;
}

@media (max-height: 600px) {
  .bar {
    bottom: 0;
  }
}
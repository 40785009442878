.burger {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: var(--font-size-xl);
  color: var(--sywt-silver);
}

.close {
  position: relative;
  top: 5px;
  left: 5px;
  font-size: var(--font-size-xl);
  margin-bottom: 10px;
}

.burger:hover {
  cursor: pointer;
  color: white;
}

.container {
  padding: 0;
  width: 100%;
  height: calc(100vh - var(--navbar-height));
  z-index: 1000;
}

.navItemList {
  background-color: var(--sywt-silver);
  width: 80%;
  margin: auto;
  padding: 0;
  border-top: 8px solid var(--sywt-gold);
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 8px;
}

.navItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  margin-top: 0;
  padding: 15px 20px;
  text-align: left;
}

.navLink {
  font-size: var(--font-size-s);
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
}

.navLink:hover, .navLink:active {
  color: var(--sywt-blue-dark) !important;
  cursor: pointer;
}

.navLink {
  color: var(--sywt-blue);
}

.logoWrapper {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 15rem;
}

.logo:hover {
  cursor: pointer;
}

.wrapper {
  display: flex;
  flex:1;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: var(--font-size-s);
  margin-top: 10px;
}

.wrapper > input {
  background-color: var(--sywt-blue-lighter);
  border-radius: 5px;
  border: none;
  font-family: "Lato", sans-serif;
  color: white;
  font-size: var(--font-size-s);
  height: 30px;
  margin-top: 3px;
  border: 2px solid transparent;
}

.wrapper > input:focus {
  outline: none !important;
  border: 2px solid var(--sywt-green);
}

.smallText {
  font-weight: 300;
  font-size: var(--font-size-xs);
  color: grey;
  margin-top: 3px;
}
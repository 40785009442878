.container {
  position: fixed;
  top: var(--navbar-height);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  padding-bottom: 30px;
  padding-top: var(--top-padding);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  z-index: -1;
}

.subContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 70%;
}

.subContainer > h3 {
  margin-bottom: 10px;
}

.contactContainer {
  display:flex;
  flex-direction:column;
}

.firstRow {
  width:100%;
  display:flex;
  justify-content: space-between;
  margin-top:20px;
}

.inputDivider {
  width: 20px;
}

.secondRow {
  width:100%;
  display:flex;
  margin-top:20px;
  margin-bottom:20px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  margin-top:20px;
}

.textContainer > label {
  display: flex;
  flex-direction: column;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 800;
  font-size: var(--font-size-s);
}

.textContainer > textarea {
  background-color: var(--sywt-blue-lighter);
  border-radius: 5px;
  border: 2px solid transparent;
  margin-top: 5px;
  font-family: "Lato", sans-serif;
  color: white;
  font-size: var(--font-size-s);
  height: 20vh;
}

.textContainer > textarea:focus {
  outline: none !important;
  border: 2px solid var(--sywt-green)
}

.buttonContainer {
  margin-top:50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.buttonContainer > button {
  margin: 0 2vw;
  padding: 0.4vw 5vw;
}

@media (max-width:900px) {
  .firstRow {
    flex-direction: column;
  }
  .subContainer {
    width: 85%;
  }
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  padding-top: 5px;
  overflow: hidden;
}

.navContent {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 60px;
}

.navItemList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  list-style-type: none;
  font-size: 1.3rem;
  width: 65%;
  padding-right: 35px;
  padding-bottom: 25px;
  margin-bottom: 0;
}

.navItem {
  white-space: nowrap;
  margin-left: 10%;
  font-size: 1.1rem;
}

.navLink {
  color: #fff !important;
  font-weight: 400;
  border-bottom: solid 3px transparent;
  padding-bottom: 10px;
}

.navLink:hover {
  color: gainsboro;
  cursor: pointer;
  border-bottom: solid 3px white;
}

.logo {
  width: 220px;
  padding-left: 30px;
  padding-top: 10px;
}

.logo:hover {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .navItemList {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 1000px) {
  .container {
    display: none;
  }
}

.wrapper > img {
  width: 220px;
  height: 220px;
  object-fit: cover;
  margin: 1.2vw 1.2vw;
  border: 3px solid white;
  border-radius: 8px;
}

.wrapper > img:hover {
  cursor: pointer;
}

.notUploaded {
  opacity: 0.5;
}

.notUploaded:hover {
  opacity: 1;
}

.uploaded {
  opacity: 1;
}

.uploaded:hover {
  opacity: 0.5;
}

.wrapper > input {
  display: none;
}

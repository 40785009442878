.container {
  display:flex;
  flex-direction:column;
  position: fixed;
  top: var(--navbar-height);
  bottom: var(--footer-height);
  left: 0;
  right: 0;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 30px;
  padding-top: var(--top-padding);
  overflow-y: auto;
  color: var(--sywt-silver);
  z-index: -1;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.container::-webkit-scrollbar {
    display: none;
}

.container h1 {
  text-align: left;
  font-size: var(--font-size-xl);
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}

.container .linkList {
  display:flex;
  flex-direction: column;
}

.container .linkList a {
  margin-bottom: 20px;
  font-size: var(--font-size-s);
}
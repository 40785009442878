.footer{
  position:fixed;
  height: var(--footer-height);
  bottom:0;
  right:0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--sywt-blue-dark);  
}

.linkContainer {
  display: flex;
  justify-content:space-around;
  width:40%;
  align-items: center;
  font-weight:300;
  color:var(--sywt-gold);
}

.copyright {
  font-size:14px;
  color:var(--sywt-silver) !important;
  font-weight:300;
  opacity: 0.3;
  padding-left: 10px;
}

.footerLink {
  font-size:14px;
  color:var(--sywt-gold) !important;
  font-weight:300;
  opacity: 0.7;
}

@media (max-width:800px) {
  .linkContainer{
    width:100%;
  }
  .copyright {
    display: none;
  }
}

@media (max-width:400px) {
  .footerLink {
    font-size: var(--font-size-xs);
  }
}

@media (max-height: 600px) {
  .footer {
    display: none;
  }
}
.container {
    display: flex;
    position: fixed;
    top: var(--navbar-height);
    bottom: var(--footer-height);
    right: 0;
    left: 0;
    overflow-y: auto;
    padding-bottom: 30px;
    padding-top: var(--top-padding);
    z-index: -1;
}

.textBlock {
    width: 60%;
    margin: auto;
    text-align: center;
}

.celebrationIcon {
    font-size: 6rem;
    color: var(--sywt-gold);
}

.textBlock > h2 {
    font-weight: 600;
}

.textBlock > h3 {
    margin-top: 10px;
    margin-bottom: 30px;
}

@media (max-width: 1100px) {
    .textBlock {
        width: 80%;
    }
}